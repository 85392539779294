@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@font-face {
  font-family: "Downtown";
  src: url("../assets/fonts/Downtown.ttf") format("truetype"), url("../assets/fonts/Downtown.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  background: #1d1d1d;
  font-family: 'Rubik', sans-serif;
  font-size: 13px;
  color: #eaeaea;
}

a {
  text-decoration: none;
  color: inherit;
}

.mainTitle {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 25px;
  user-select: none;

  &.normal {
    text-transform: none;
  }

  &.center {
    margin-top: auto;
    margin-bottom: auto;
  }

  &.clickable {
    cursor: pointer;
  }

  .list {
    color: #5cf0bc;
  }

  &:before, &:after {
    color: #2194bb;
  }

  &:before {
    content: "<";
    margin-right: 5px;
  }

  &:after {
    content: ">";
    margin-left: 5px;
  }

  @media only screen and (max-width: 490px) {
    font-size: 25px;
  }
}

#root {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.page {
  padding-top: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:not(.homepage) {
    position: relative;

    &:before, &:after {
      position: absolute;
      top: 0;
      content: "";
      height: 100%;
      z-index: 1;
    }

    &:before {
      left: 0;
      width: 445px;

      background: url(../assets/images/full_background_LEFT.svg) no-repeat;
      background-position-x: -207px;
      background-position-y: -100px;
    }

    &:after {
      right: 0;
      width: 385px;

      background: url(../assets/images/full_background_RIGHT.svg) no-repeat;
      background-position-x: 82px;
      background-position-y: -175px;
    }

    @media only screen and (max-width: 1280px) {
      &:before, &:after {
        display: none;
      }
    }
  }


  .inner {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 5;
  }

  .errorMessage, .successMessage {
    width: 100%;
    padding: 15px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .errorMessage {
    background: #c3311f;
  }

  .successMessage {
    background: #2e9249;
  }

  input:not([type=file]):not([type=checkbox]), select, textarea {
    outline: none;
  }
  input:not([type=file]):not([type=checkbox]):focus, select:focus, textarea:focus {
    outline: 1px solid #5dabd0;
    outline-offset: -1px;
  }

  .form-group {
    & + .form-group {
      margin-top: 15px;
    }

    > label {
      font-size: 13px;
      font-weight: 500;
      display: block;
      text-transform: uppercase;
      margin-bottom: 9px;

      & + input:not([type="file"]):not([type=checkbox]), & + select {
        width: 100%;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 300;
        background: rgba(67, 67, 67, 0.5);
        border-radius: 5px;
        padding: 12px;
        color: #fff;
        -webkit-appearance: initial;
      }

      & + input[type=checkbox] {
        width: 26px;
        height: 26px;
        border: 2px solid #3aadd4;
        border-radius: 4px;
      }
      & + input[type=checkbox]:checked {
        background: #3aadd4 url(../assets/images/icon_checkbox.svg) no-repeat center;
      }
    }
  }

  .submitButton {
    margin: 25px auto 0;
    background: #2194bb;
    border-radius: 3px;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 16px 65px 13px;
    display: block;
    cursor: pointer;
  }

  footer.mainFooter {
    padding: 40px 0;
    font-size: 11px;
    font-weight: 400;
    opacity: .5;
    text-align: center;

    &.sticky {
      flex-shrink: 0;
      margin-top: auto;
    }
  }
}

.loaderBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #161616;
  opacity: .8;
}

.loader {
  position: absolute;
  user-select: none;
  overflow-x: hidden;

  &.inline {
    position: static;

    &.center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:not(.inline).center {
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }

  &.small {
    &:before {
      width: 52px;
      height: 52px;
    }

    > p {
      font-size: 11px;
    }
  }

  &:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: url(../assets/images/loader_logo.png) no-repeat center / cover;
    animation: beat 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  > p {
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 0;

    &.small {
      font-size: 11px;
    }
  }
}

@keyframes beat {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
