$formWidth: 520px;
$padding: 40px;

.page.recover {
  .inner {
    width: 100%;
    max-width: $formWidth + $padding * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $padding;

    @media only screen and (max-width: 420px) {
      padding: 0 calc($padding / 2);
    }
  }
}
