$circleWidth: 86px;
$circleHeight: 86px;
$statWidth: 135px;
$marginBetweenSections: 80px;

$bgLeftTopWidth: 432px;
$bgLeftTopHeight: 863px;

$bgRightTopWidth: 451px;
$bgRightTopHeight: 541px;

$bgLeftBottomWidth: 233px;
$bgRightBottomWidth: 217px;

.page.homepage {
  padding-top: 100px;
  position: relative;

  @media only screen and (max-width: 590px) {
    padding-top: 50px;
  }

  @media only screen and (max-width: 1280px) {
    .bg {
      &:before, &:after, > span:before, > span:after {
        display: none;
      }
    }
  }

  .bg {
    &:before, &:after, > span:before, > span:after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
    }

    &:before {
      left: 0;
      width: $bgLeftTopWidth;
      height: $bgLeftTopHeight;

      background: url(../assets/images/blue_graphic_left_gora.svg) no-repeat;
      z-index: 1;
    }

    &:after {
      right: 0;
      width: $bgRightTopWidth;
      height: $bgRightTopHeight;

      background: url(../assets/images/blue_graphic_right_gora.svg) no-repeat;
      z-index: 1;
    }

    > span:nth-child(1) {
      &:before {
        top: $bgLeftTopHeight - $bgLeftTopHeight * .4;
        left: 0;
        width: $bgLeftTopWidth;
        height: $bgLeftTopHeight * .4;

        background: -moz-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(29,29,29,0.5)), color-stop(25%, rgba(29,29,29,0.7)), color-stop(50%, rgba(29,29,29,0.9)), color-stop(75%, rgba(29,29,29,0.95)), color-stop(100%, rgba(29,29,29,1)));
        background: -webkit-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: -o-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: -ms-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: linear-gradient(to bottom, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        z-index: 2;
      }

      &:after {
        top: $bgRightTopHeight - $bgRightTopHeight * .4;
        right: 0;
        width: $bgRightTopWidth;
        height: $bgRightTopHeight * .4;;

        background: -moz-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(29,29,29,0.5)), color-stop(25%, rgba(29,29,29,0.7)), color-stop(50%, rgba(29,29,29,0.9)), color-stop(75%, rgba(29,29,29,0.95)), color-stop(100%, rgba(29,29,29,1)));
        background: -webkit-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: -o-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: -ms-linear-gradient(top, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        background: linear-gradient(to bottom, rgba(29,29,29,0.5) 0%, rgba(29,29,29,0.7) 25%, rgba(29,29,29,0.9) 50%, rgba(29,29,29,0.95) 75%, rgba(29,29,29,1) 100%);
        z-index: 2;
      }
    }

    > span:nth-child(2) {
      &:before {
        top: $bgLeftTopHeight + 235;
        left: 0;
        width: $bgLeftBottomWidth;
        height: 876px;

        background: url(../assets/images/blue_graphic_left.svg) no-repeat;
        z-index: 2;
      }

      &:after {
        top: $bgRightTopHeight + 313;
        right: 0;
        width: $bgRightBottomWidth;
        height: 703px;

        background: url(../assets/images/blue_graphic_right.svg) no-repeat;
        z-index: 2;
      }
    }
  }


  .inner {
    width: 100%;
    max-width: 890px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 40px;
    position: relative;
    z-index: 5;

    @media only screen and (max-width: 420px) {
      padding: 0 40px / 2;
    }

    > section {
      &:not(:first-child) {
        margin-top: $marginBetweenSections;
      }

      &.sec1 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
          font-family: 'Downtown', sans-serif;
          font-size: 62px;
          text-transform: uppercase;

          > span {
            font-size: 95px;
            color: #2194bb;
            margin-right: 10px;
          }

          > sup {
            font-size: 40px;
            color: #2194bb;
            margin-left: 20px;
          }
        }

        .text {
          margin-top: 15px;
          font-family: 'Downtown', sans-serif;
          font-size: 45px;

          .flag {
            width: 31px;
            height: 38px;
            display: inline-block;

            background: url(../assets/images/flag_icon.svg) no-repeat;
            background-size: contain;
          }
        }

        .stats {
          width: 60%;
          margin-top: 25px;
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;

          .stat {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: $statWidth;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;

              width: $circleWidth;
              height: $circleHeight;

              font-size: 32px;
              border: 2px solid #2194bb;
              border-radius: 50%;
            }

            > p {
              font-size: 13px;
              font-weight: 500;
              text-transform: uppercase;
              text-align: center;
              margin-top: 15px;
            }
          }
        }

        .timer {
          margin-top: 60px;

          h4 {
            font-size: 21px;
            font-weight: 500;
            text-transform: uppercase;
            text-align: center;
          }

          .time {
            margin-top: 10px;
          }
        }

        .register {
          margin-top: 25px;
          font-size: 15px;
          font-weight: 500;
          text-align: center;
          background: #2194bb;
          border-radius: 3px;
          text-transform: uppercase;
          display: block;
          padding: 14px 55px;
        }

        @media only screen and (max-width: 820px) {
          .stats {
            width: 100%;
          }
        }

        @media only screen and (max-width: 590px) {
          .text {
            font-size: 32px;
          }
        }

        @media only screen and (max-width: 490px) {
          .stats {
            flex-direction: column;
            align-items: center;

            .stat {
              margin-bottom: 25px;
            }
          }
        }

        @media only screen and (max-width: 445px) {
          .logo {
            font-size: 42px;

            > span {
              font-size: 46px;
            }
          }

          .text {
            font-size: 24px;

            .flag {
              width: 21.7px;
              height: 26.6px;
            }
          }
        }

        @media only screen and (max-width: 365px) {
          .text {
            font-size: 19px;
          }
        }
      }

      &.sec2 {
        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          text-align: justify;
        }
      }

      &.sec7 {
        margin-top: 20px;
        p {
          margin: 10px;
          margin-left: 0;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          text-align: justify;
        }
        li {
          margin-left: 20px;
        }
        a {
          color: #3aadd4;
          font-weight: 500;
        }
      }

      &.sec3 {
        a {
          color: #3aadd4;
          font-weight: 500;
        }

        ul {
          list-style: none;
          display: flex;
          flex-wrap: wrap;

          li {
            flex: 0 0 25%;
            margin-bottom: 25px;
            margin-right: 8%;

            h4 {
              font-size: 17px;
              font-weight: 500;
              margin-bottom: 10px;
              text-transform: uppercase;
            }

            p {
              font-size: 21px;
              font-weight: 700;
            }
          }
        }

        @media only screen and (max-width: 820px) {
          ul {
            justify-content: space-between;

            li {
              flex-basis: 40%;
              margin-right: 0;
              margin-bottom: 45px;
            }
          }
        }

        @media only screen and (max-width: 590px) {
          ul li {
            flex-basis: 100%;
            text-align: center;
          }
        }
      }

      &.sec4 {
        ul {
          list-style: none;
          display: flex;
          justify-content: space-between;
          li {
            margin: 30px;
            display: flex;
            &:before {
              width: 33px;
              height: 58px;
              content: "";
              background: url("../assets/images/medal_icon.svg") no-repeat;
              margin-right: 25px;
            }
            > div {
              display: flex;
              flex: 1 1 0px;
              flex-direction: column;
              h4 {
                font-size: 22px;
                font-weight: 500;
                text-transform: uppercase;
              }
              p.cash {
                font-size: 27px;
                font-weight: 700;
                // &:before {
                //   content: "$";
                //   font-size: 22px;
                // }
              }

              p.additional {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 700;

                &:before {
                  content: "+ ";
                  font-size: 18px;
                }
              }
            }
          }
        }
        @media only screen and (max-width: 820px) {
          ul {
            flex-direction: column;
            align-items: center;
            li {
              margin-bottom: 25px;
              width: 240px;
            }
          }
        }
        
        p {
          margin: 10px;
          margin-left: 0;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          // text-align: center;
        }
      }

      $tob_logo_size: 130px;
      $osec_logo_size: 70px;
      $orange_logo_size: 110px;
      $sf_logo_size: 60px;
      $hr_logo_size: 90px;
      $bs_logo_size: 65px;
      $in_logo_size: 90px;
      $tl_logo_size: 80px;
      $ax_logo_size: 140px;

      $burp_top_adjustment: 10px;
      $logo_margin_top_adjustment: 10px;

      &.sec5 {
        .tob {
          img {
            height: $tob_logo_size;
          }
        }
        
        .osec {
          img {
            height: $osec_logo_size;
          }
        }

        .orange {
          img {
            height: $orange_logo_size;
          }
        }

        .sf {
          img {
            margin-top: $logo_margin_top_adjustment;
            height: $sf_logo_size;
          }
        }

        .hr {
          img {
            height: $hr_logo_size;
          }
        }

        .bs {
          img {
            height: $bs_logo_size;
            margin-top: $burp_top_adjustment;
          }
        }

        .in {
          img {
            height: $in_logo_size;
          }
        }

        .tl {
          img {
            height: $tl_logo_size;
          }
        }

        .ax {
          img {
            height: $ax_logo_size;
          }
        }

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            &:not(:first-child) {
              margin-left: 50px;
            }
          }

          img {
            margin: 50px 10px;
          }

          @media only screen and (max-width: 620px) {
            flex-direction: column;

            li {
              margin-left: 0 !important;
              margin-bottom: 40px;
            }

            $scale: 0.6;
  
            .tob {
              img {
                height: $tob_logo_size * $scale;
              }
            }
            
            .osec {
              img {
                height: $osec_logo_size * $scale;
              }
            }

            .orange {
              img {
                height: $orange_logo_size;
              }
            }

            .sf {
              img {
                margin-top: $logo_margin_top_adjustment * $scale;
                height: $sf_logo_size * $scale;
              }
            }
    
            .hr {
              img {
                height: $hr_logo_size * $scale;
              }
            }

            .bs {
              img {
                height: $bs_logo_size * $scale;
                margin-top: $burp_top_adjustment * $scale;
              }
            }
    
            .in {
              img {
                height: $in_logo_size * $scale;
              }
            }

            .tl {
              img {
                height: $tl_logo_size * $scale;
              }
            }
    
            .ax {
              img {
                height: $ax_logo_size * $scale;
              }
            }
          }
        }

        p {
          margin: 10px;
          margin-left: 0;
          font-size: 15px;
          font-weight: 500;
          line-height: 23px;
          text-align: center;
        }
      }

      &.sec6 {
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 21px;
          font-weight: 700;

          li {
            &:before {
              display: inline-block;
              vertical-align: middle;
              margin-right: 16px;
            }

            &.email {
              &:before {
                width: 37.3px;
                height: 28px;
                content: "";
                background: url("../assets/images/email_icon.svg") no-repeat;
              }
            }

            &.twitter {
              &:before {
                width: 33.9px;
                height: 28px;
                content: "";
                background: url("../assets/images/twitter_icon.svg") no-repeat;
              }
            }
          }
        }

        @media only screen and (max-width: 820px) {
          ul {
            flex-direction: column;

            li {
              margin-bottom: 25px;
            }
          }
        }

        @media only screen and (max-width: 450px) {
          ul {
            font-size: 18px;

            li:before {
              display: none;
            }
          }
        }
      }
    }
  }
}