$formWidth: 340px;
$padding: 40px;

.page.settings {
  .inner {
    width: 100%;
    max-width: $formWidth + $padding * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $padding;

    @media only screen and (max-width: 420px) {
      padding: 0 calc($padding / 2);
    }

    .avatar {
      text-align: center;

      .customFile {
        > input[type="file"] {
          display: none;

          & + label {
            img {
              cursor: pointer;
              max-width: 128px;
            }
          }
        }
      }
    }

    .academic {
      display: inline-grid;
    }

    span.tick {
      width: 26px;
      height: 26px;
      border: 2px solid #3aadd4;
      border-radius: 4px;

      &.checked {
        background: #3aadd4 url(../assets/images/icon_checkbox.svg) no-repeat center;
      }
    }
  }
}