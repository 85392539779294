$width: 650px;
$padding: 40px;

.page.announcements {
  .inner {
    width: 100%;
    max-width: $width + $padding * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $padding;
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;

      .mainTitle {
        margin-bottom: 0;
      }

      .hide, .addQuestion {
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 5px 25px;
        display: block;
        cursor: pointer;
        outline: none;
      }

      .hide {
        margin-right: 10px;
        background: #cb3434;

        &.active {
          background: #2194bb;
        }
      }

      .addQuestion {
        margin-left: 10px;
        background: #2194bb;
      }
    }

    .list {
      .announcement {
        &:not(:first-child) {
          &:before {
            content: "";
            height: 1px;
            background: #3a3a3a;
            display: block;
            margin: 24px 0;
          }
        }

        header {
          display: flex;
          align-items: center;
          margin-bottom: 9px;

          h2 {
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 41px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .label {
            border: 1px solid #fff;
            border-radius: 3px;
            padding: 4px 13px;
            opacity: .5;
            font-size: 8.4px;
            letter-spacing: .1px;
            text-transform: uppercase;
            margin: 0 12px;
          }

          .date {
            margin-left: auto;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            flex: 0 0 165px;
            text-align: right;
          }
        }

        > .description {
          margin-top: 20px;
          font-size: 15px;
          line-height: 23px;

          * {
            margin: initial;
            padding: initial;
          }

          img {
            max-width: 100%;
            display: block;
          }

          code {
            background: #434343;
            border-radius: 1px;
            letter-spacing: 0.5px;
            padding: 2px 6px;
          }

          pre {
            padding: 16px;
            background: #434343;
            border-radius: 1px;

            code {
              padding: 0;
              line-height: 23px;
            }
          }

          p {
            margin-top: 1em;
            margin-bottom: 1em;
          }

          ul, ol {
            margin-top: 1em;
            margin-bottom: 1em;
            padding-left: 40px;
          }

          a {
            color: #3aadd4;
            text-decoration: underline;
          }

          > :last-child {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
          }
        }

        @media only screen and (max-width: 720px) {
          header {
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;

            h2 {
              order: 1;
            }

            .label {
              order: 0;
              margin-left: 0;
            }

            .date {
              order: 2;
              margin-left: initial;
              flex: auto;
            }
          }
        }
      }

      .question {
        &:not(:first-child) {
          &:before {
            content: "";
            height: 1px;
            background: #3a3a3a;
            display: block;
            margin: 24px 0;
          }
        }

        header {
          display: flex;
          align-items: center;
          margin-bottom: 9px;
          background: transparent;
          user-select: none;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, .1);
          }

          h2 {
            font-size: 17px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 41px;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .label {
            border: 1px solid #fff;
            border-radius: 3px;
            padding: 4px 13px;
            opacity: .5;
            font-size: 8.4px;
            letter-spacing: .1px;
            text-transform: uppercase;
            margin: 0 12px;
          }

          .date {
            margin-left: auto;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            flex: 0 0 165px;
            text-align: right;
          }
        }

        .messages {
          .message {
            padding: 10px;
            background: transparent;

            &:not(:first-child) {
              margin-top: 10px;

            }

            &:nth-child(even) {
              background: rgba( 0, 0, 0, .2);
            }

            h5 {
              font-size: 14px;
              margin-bottom: 7px;
            }

            .body {
              font-size: 15px;
            }
          }
        }

        @media only screen and (max-width: 720px) {
          header {
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
            padding-top: 5px;
            padding-bottom: 5px;

            h2 {
              order: 1;
            }

            .label {
              order: 0;
              margin-left: 0;
            }

            .date {
              order: 2;
              margin-left: initial;
              flex: auto;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 420px) {
      padding: 0 calc($padding / 2);

      .top {
        flex-wrap: wrap;

        .mainTitle {
          order: 0;
          flex: 100%;
          margin-bottom: 5px !important;
        }

        .hide {

          order: 1
        }

        .addQuestion {

          order: 2;
        }
      }
    }
  }

  .questionForm {
    h1 {
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .form {
      margin-top: 20px;
      width: 100%;
      overflow: hidden;

      textarea {
        width: 100%;
        background: #434343;
        border-radius: 3px 0 0 3px;
        padding: 12px 14px;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        color: rgba(255, 255, 255, .5);
        resize: none;
      }

      button {
        margin: 25px auto 0;
        background: #2194bb;
        border-radius: 3px;
        color: #fff;
        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 16px 65px 13px;
        display: block;
        cursor: pointer;
      }
    }
  }
}
